import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { AnalyticsSnippet } from '@segment/analytics-next';

import 'core-js/stable';

import { addAmplitudeToConfigHeader } from './resources/amplitude/addAmplitudeConfigToHeader';
import { initGoogleMaps } from './utils/external-libs/googleMaps';
import { initSegment } from './utils/external-libs/segment';
import { setupSentry } from './utils/external-libs/sentry';
import lazyWithRetry from './utils/lazyWithRetry';

const HomeOwnerFlowWidget = lazyWithRetry(() => import('./components/InitApp/HomeOwnerFlowWidget'));

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
    GOOGLE_RESERVE?: {
      conversion_tracking_url: string;
      partner_id: string;
    };
    SENTRY?: {
      enabled: boolean;
      environment: string;
    };
    SEGMENT?: {
      enabled: boolean;
      jsKey: string;
      cdnEndpointUrl: string;
      trackingEndpointUrl: string;
    };
    GOOGLE_MAPS_API_KEY: string;
    AMPLITUDE_ENDPOINT_URL?: string;
    BOOK_HOUSECALL_URL?: string;
    Cypress: unknown;

    HC_ENV_REVISION: string;
    HC_ENV_NODE_ENV: string;
    HC_ENV_APP_ENVIRONMENT: string;
    RECAPTCHA_ENTERPRISE_KEY: string;
    HOUSECALL_SERVER_URL: string;
    STRIPE_PUBLISHABLE_KEY: string;
  }
}

window.BOOK_HOUSECALL_URL = BOOK_HOUSECALL_URL;
window.AMPLITUDE_ENDPOINT_URL = AMPLITUDE_ENDPOINT_URL;
window.GOOGLE_MAPS_API_KEY = GOOGLE_MAPS_API_KEY;
window.SENTRY = SENTRY;
window.SEGMENT = SEGMENT;
window.RECAPTCHA_ENTERPRISE_KEY = RECAPTCHA_ENTERPRISE_KEY;
window.HOUSECALL_SERVER_URL = HOUSECALL_SERVER_URL;
window.STRIPE_PUBLISHABLE_KEY = STRIPE_PUBLISHABLE_KEY;
window.GOOGLE_RESERVE = GOOGLE_RESERVE;

const appendStyles = () => {
  document.head.insertAdjacentHTML(
    'beforeend',
    `<style>
  body {
    background: transparent !important;
    height: 100% !important;
    margin: 0 !important;
  }
  html {
    height: 100% !important;
  }
  iframe {
    width: 100% !important;
    height: 100% !important;
    display: block;
  }
  #root {
    width: 100% !important;
    height: 100% !important;
  }
  </style>`
  );
};

const shouldRenderNewOnlineBooking = () => {
  return window.location.href.search('[?&]v2=true') !== -1;
};

const mountComponents = () => {
  const root = document.getElementById('root');
  ReactDOM.render(
    <Suspense fallback={<div />}>
      <HomeOwnerFlowWidget />
    </Suspense>,
    root
  );
};

const initNewApp = () => {
  initSegment();
  initGoogleMaps();
  setupSentry();
  addAmplitudeToConfigHeader();
  mountComponents();
};

const initOldApp = () => {
  appendStyles();
  const urlWithoutOrigin = window.location.href.split(window.location.origin)[1];

  const root = document.getElementById('root');
  ReactDOM.render(
    <iframe
      src={`${ONLINE_BOOKING_V1_URL}${urlWithoutOrigin}`}
      width="100%"
      height="100%"
      frameBorder="0"
      title="Online booking"
    />,
    root
  );
};

(function startOLB() {
  if (shouldRenderNewOnlineBooking()) {
    initNewApp();
  } else {
    initOldApp();
  }
})();
